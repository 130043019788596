import { FC } from 'react'
import styles from './IndustryLeaders.module.scss'
import { IndustryLeadersProps } from './types'
import { getDefaultPortraits } from 'components/Phantom/_sections/IndustryLeaders/utils'
import { Img } from 'components/basic/Img'

export const IndustryLeaders: FC<IndustryLeadersProps> = (props) => {
	const { portraits = getDefaultPortraits() } = props
	return (
		<div className={styles.container}>
			<header>
				<p>Scientific Advisory Board</p>
				<h2>Backed by industry leaders</h2>
			</header>

			<ul className={styles.leader_list}>
				{portraits.map((portrait, index) => {
					return (
						<li
							key={index}
							className={styles.leader}
						>
							<Img
								src={portrait.image}
								alt={portrait.name}
								dprHeight={600}
								objectFit={'cover'}
							/>
							<div className={styles.text}>
								<h3>{portrait.name}</h3>
								<p>{portrait.title}</p>
							</div>
						</li>
					)
				})}
			</ul>

			<div className={styles.moire_container}>
				<img
					src={'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/moire_1.png'}
					alt={'moire'}
				/>
				<img
					src={'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/moire_2.png'}
					alt={'moire'}
				/>
			</div>
		</div>
	)
}
