import { FC, useEffect, useRef, useState } from 'react'
import styles from './Pod4Hero.module.scss'
import { Pod4HeroProps } from './types'
import { Button } from 'components/Phantom/Button'
import { ResponsiveVideo } from 'components/basic/Video'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { Img } from 'components/basic/Img'
import { FullScreenModal } from 'components/Phantom/_sections/ThisIsPod4Ultra'
import { Icon } from 'components/Phantom/Icon'
import { observer } from 'mobx-react'
import { useRootStore } from 'components/_hooks/useRootStore'
import cx from 'classnames'
import { CTAProps } from 'components/_utils/utilityTypes'

export const Pod4Hero: FC<Pod4HeroProps> = (props) => {
	const { tag, header, subheader, content, cta, eyebrow, shade, videoButton, anchorHref, ctaFooterContent, mobilePosition = 'center', maxHeaderWidth, leftAligned = false, textTheme = 'white' } = props

	return (
		<div className={cx(styles.container, { [styles.container_mobile_top]: mobilePosition === 'top' }, { [styles.container_left_aligned]: leftAligned })}>
			{tag && <p className={styles.tag}>{tag}</p>}
			{eyebrow && <p className={styles.eyebrow}>{eyebrow}</p>}
			<header className={cx(styles.header, { [styles.header_left_aligned]: leftAligned }, { [styles.header_text_theme_black]: textTheme === 'black' })}>
				<h2 style={{ maxWidth: maxHeaderWidth || '13ch' }}>{header}</h2>
				{subheader && <p>{subheader}</p>}
			</header>
			{cta || videoButton ? (
				<div className={cx(styles.cta_container, ctaFooterContent ? styles.cta_column : '')}>
					<HeroCTA
						cta={cta}
						textTheme={textTheme}
					/>
					{ctaFooterContent}
					{videoButton && (
						<Button.Empty
							onClick={videoButton.onClick}
							id={'pod4_hero_video_button'}
							className={styles.video_button}
						>
							<Icon
								name={'PlayDark'}
								color={'white'}
							/>
							{videoButton.text}
						</Button.Empty>
					)}
				</div>
			) : null}

			{shade && <div className={styles.shade} />}

			<div className={styles.content_container}>{content}</div>

			{anchorHref && (
				<div className={styles.anchor_link_wrapper}>
					<AnchorLink
						href={anchorHref}
						aria-label={'Arrow down'}
						offset={54}
					>
						<Img
							simple
							src={'https://eightsleep.imgix.net/chevron-down-48.png?v=1601656369'}
							lazy={false}
							alt="arrow pointing down"
						/>
					</AnchorLink>
				</div>
			)}
		</div>
	)
}

const HeroCTA = (props: { cta: CTAProps; textTheme: 'white' | 'black' }) => {
	const { cta } = props
	if (!cta) return null

	if (props.textTheme === 'black') {
		return (
			<Button.Dark
				id={'pod4_hero_cta'}
				href={cta.href}
			>
				{cta.text}
			</Button.Dark>
		)
	}

	return (
		<Button.Primary
			id={'pod4_hero_cta'}
			href={cta.href}
		>
			{cta.text}
		</Button.Primary>
	)
}

export const homepageProps: Pod4HeroProps = {
	// tag: 'Pod 4',
	header: 'Cooler nights. Stronger days.',
	content: (
		<Img
			src={'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/summer_hero_desktop.jpg'}
			sources={[
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/summer_hero_mobile_cropped.jpg',
					mediaQuery: '(max-width: 1024px)',
				},
			]}
			objectFit={'cover'}
			alt={'The Pod 4 sitting on ice'}
		/>
	),
	cta: {
		text: 'Learn more',
		href: '/pod-cover',
	},
	mobilePosition: 'top',
}

export const Pod4HeroWalking = (props: { id?: string }) => {
	return (
		<div className={styles.container_newstyle}>
			<header className={styles.header_newstyle}>
				<h2>Nights that fuel your best days</h2>
				<p>Turn any bed into the ultimate sleeping experience.</p>
			</header>
			<div className={styles.cta_container_newstyle}>
				<Button.White
					id={'pod4_hero_cta'}
					href={'/pod-cover'}
				>
					Learn more
				</Button.White>
			</div>

			<div className={styles.content_container}>
				<Img
					src={'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/newwalkinghero.png'}
					objectFit={'cover'}
					alt={'A figure walking towards a bed. The focus of the image is on the Eight Sleep Pod beside the nightstand.'}
					doNotCompress
					className={styles.walking_hero}
				/>
			</div>
		</div>
	)
}

const coolingVideo = (
	<ResponsiveVideo
		videos={[
			{
				url: 'https://eightsleep-react-assets.s3.us-east-2.amazonaws.com/assets/prospecting-cooling-hero-mobile.mp4',
				breakpoint: 0,
				x: 374,
				y: 460,
				thumb: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/prospecting-cooling-hero-mobile.jpg',
			},
			{
				url: 'https://eightsleep-react-assets.s3.us-east-2.amazonaws.com/assets/prospecting-cooling-hero-desktop.mp4',
				breakpoint: 1024,
				x: 1440,
				y: 640,
				thumb: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/prospecting-cooling-hero-desktop.jpg',
			},
		]}
		caption={''}
	/>
)

export const Pod4HeroHomepageVideo = (props: { id?: string }) => {
	return (
		<div className={styles.container_newstyle}>
			<header className={styles.header_newstyle}>
				<h2>Cooler nights. Stronger days.</h2>
				{/*<p>Turn any bed into the ultimate sleeping experience.</p>*/}
			</header>
			<div className={styles.cta_container_newstyle}>
				<Button.White
					id={'pod4_hero_cta'}
					href={'/pod-cover'}
				>
					Learn more
				</Button.White>
			</div>
			<div className={styles.content_container}>{coolingVideo}</div>
		</div>
	)
}

const coverFalling = (
	<ResponsiveVideo
		videos={[
			{
				url: 'https://d115sb6i1ixllw.cloudfront.net/assets/2024-09-04-hero-mobile.mp4',
				breakpoint: 0,
				x: 374,
				y: 460,
				thumb: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/2024-09-04-hero-mobile.jpg',
			},
			{
				url: 'https://d115sb6i1ixllw.cloudfront.net/assets/2024-09-04-hero-desktop.mp4',
				breakpoint: 1024,
				x: 1440,
				y: 640,
				thumb: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/2024-09-04-hero-desktop.jpg',
			},
		]}
		caption={''}
	/>
)

export const Pod4HeroCoverFalling = (props: { id?: string }) => {
	return (
		<Pod4Hero
			{...homepageProps}
			content={coverFalling}
			textTheme={'black'}
			leftAligned
			{...props}
			shade
			cta={{
				...homepageProps.cta,
				href: homepageProps.cta.href,
			}}
		/>
	)
}

export const clinicalProps: Pod4HeroProps = {
	header: 'Clinically-backed sleep fitness',
	content: (
		<Img
			src={'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/new_clinical_tech_hero.png'}
			objectFit={'cover'}
			alt={'A couple lying on the Pod 4 in a modern bedroom'}
			className={styles.clinical_image}
		/>
	),
	anchorHref: '#clinical_results',
	shade: true,
}

export const Pod4HeroClinical = (props: { id?: string }) => {
	return (
		<Pod4Hero
			{...clinicalProps}
			{...props}
		/>
	)
}

export const hiwProps: Pod4HeroProps = {
	// tag: 'Pod 4',
	header: 'The new standard in sleep',
	content: (
		<ResponsiveVideo
			videos={[
				{
					url: 'https://d115sb6i1ixllw.cloudfront.net/assets/hiw_hero_mobile_final.mp4',
					breakpoint: 0,
					x: 374,
					y: 460,
					thumb: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/hiw_hero_loop_thumb_mobile.jpg',
				},
				{
					url: 'https://d115sb6i1ixllw.cloudfront.net/assets/hiw_hero_desktop_final.mp4',
					breakpoint: 1024,
					x: 1440,
					y: 640,
					thumb: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/hiw_hero_loop_thumb.jpg',
				},
			]}
			preload={'auto'}
			caption={''}
		/>
	),
	cta: {
		text: 'Shop now',
		href: '/product/pod-cover',
	},
	videoButton: {
		text: 'Watch the video',
		onClick: () => {},
	},
}

export const Pod4HeroHIW = observer(() => {
	const { metricRegion } = useRootStore().settingsStore
	const [videoVisible, setVideoVisible] = useState(false)

	const videoRef = useRef<HTMLVideoElement>(null)

	useEffect(() => {
		if (videoRef.current) {
			if (videoVisible) {
				videoRef.current.play()
			} else {
				videoRef.current.pause()
			}
		}
	}, [videoVisible])

	return (
		<>
			<Pod4Hero
				{...hiwProps}
				videoButton={{
					text: hiwProps.videoButton?.text,
					onClick: () => setVideoVisible(true),
				}}
			/>
			<FullScreenModal
				closeAction={() => setVideoVisible(false)}
				visible={videoVisible}
				aspectRatio={'16/9'}
			>
				<div className={styles.video_container}>
					<video
						ref={videoRef}
						src={
							metricRegion ? 'https://d115sb6i1ixllw.cloudfront.net/assets/pod_4_launch_metric_subtitles.mp4' : 'https://d115sb6i1ixllw.cloudfront.net/assets/Pod%204%20launch%20with%20subtitles.mp4'
						}
						preload={'none'}
						autoPlay={false}
						loop={false}
						controls
					/>
				</div>
			</FullScreenModal>
		</>
	)
})
