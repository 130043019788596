import React from 'react'
import { Main } from 'layouts'
import { PersonalSleepConnected } from 'components/Phantom/_sections/PersonalSleep'
import { SlideOutBenefits } from 'components/Phantom/_sections/SlideOutBenefits'
import { WhoWeAre } from 'components/Phantom/_sections/WhoWeAre'
import { FeaturedReviews } from 'components/Phantom/_sections/FeaturedReviews'
import { ScrollSequenceStatic } from 'components/Phantom/_sections/ScrollSequenceStatic'
import { Pod4HeroWalking } from 'components/Phantom/_sections/Pod4Hero'
import { IntelligentSystem } from 'components/Phantom/_sections/IntelligentSystem'
import { NewStandardConnected } from 'components/Phantom/_sections/NewStandard'
import { NoPhoneConnected } from 'components/Pod4NoPhone'
import { IndustryLeaders } from 'components/Phantom/_sections/IndustryLeaders'
import { AutopilotBrieferCTAStoreConnected } from 'components/Phantom/_sections/AutopilotBriefer'
import { TruemedIntraPageComponentConnected } from 'components/TruemedIntraPageComponent'
import { ProductBreakdownConnected } from 'components/ProductBreakdown'

export const Home2024 = () => {
	const anchorId = 'intelligent-system'

	return (
		<Main inverted>
			<Pod4HeroWalking id={anchorId} />
			<IntelligentSystem id={anchorId} />
			<ProductBreakdownConnected />
			<NewStandardConnected
				ctaUrl="/pod-cover"
				afterIntelligentSystem
			/>
			<IndustryLeaders />
			<FeaturedReviews />
			<PersonalSleepConnected ctaUrl="/pod-cover" />
			<AutopilotBrieferCTAStoreConnected />
			<NoPhoneConnected ctaUrl="/pod-cover" />
			<SlideOutBenefits ctaUrl="/pod-cover" />
			<WhoWeAre />
			<TruemedIntraPageComponentConnected />
			<ScrollSequenceStatic ctaUrl="/pod-cover" />
		</Main>
	)
}
