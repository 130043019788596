import { PortraitProps } from './types'

export const getDefaultPortraits = (): PortraitProps[] => {
	return [
		{
			name: 'Peter Attia, MD',
			title: 'Physician and founder of Early Medical',
			image: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/attia_leader.jpg',
		},
		{
			name: 'Andrew Huberman, Ph.D.',
			title: 'Neuroscientist and Stanford professor',
			image: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/huberman_leader.jpg',
		},
		{
			name: 'Matthew Walker',
			title: 'Ph.D Neuroscientist & UC Berkeley professor',
			image: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/matthew_walker_375_300_frame.jpg',
		},
	]
}
