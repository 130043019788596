import { GetStaticPaths, GetStaticProps } from 'next'
import { inject, observer } from 'mobx-react'
import { RootStore } from 'stores/index'
import { generatePathPermutationsForExperiments } from 'ab_testing'
import { config } from 'utils/internationalization'
import { Home2024 } from 'components/WebEv/Home2024'

interface IStaticProps {
	rootStore: RootStore
}

function Index() {
	return <Home2024 />
}

export const getStaticProps: GetStaticProps = async () => {
	const props: Partial<IStaticProps> = {}

	return {
		props,
	}
}

export const getStaticPaths: GetStaticPaths = async () => {
	const regionPaths = config.allRegions.map((it) => ({ params: { index: '', locale: it } }))
	const paths = [...regionPaths].flatMap((it) => generatePathPermutationsForExperiments(it.params.index).map((p) => ({ params: { locale: it.params.locale, experiments: p } })))
	return {
		paths,
		fallback: false,
	}
}

export default inject('rootStore')(observer(Index))
